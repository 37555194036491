import FAQ, {type FAQProps} from '@/components/brochureV2/section/FAQ/FAQ';

export default function PosFAQ(props: FAQProps) {
  const iconStyles =
    'bg-shade-90 text-white border-2 border-white group-hover:bg-shade-70 group-active:bg-shade-50';
  const borderStyles = 'border-white border-opacity-[15%]';
  return (
    <FAQ
      {...props}
      plusIconStyles={iconStyles}
      minusIconStyles={iconStyles}
      borderShade={borderStyles}
    />
  );
}
